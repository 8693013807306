.shipify-data-section-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shipify-data-section-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.shipify-data-section-title {
    font-size: 13px;
    color: rgb(90, 90, 90);
    font-weight: 500;
}

.shipify-data-section-value {
    font-size: 25px;
    color: black;
}