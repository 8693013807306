.shipify-pricing-rules-row{
    align-items: center;
    display: flex;
    flex-direction: row;
}


.account-page-section-content{
    padding: 0 15px;
}

.shpify-supplier-link-img {
    margin-left: 10px;
}


.supplier-logo-svg {
    width: 25px;
    height: 25px;
}

.shipify-supplier-link-btn{
    border-radius: 8px !important;
    background: -webkit-linear-gradient(white, white) padding-box,
    linear-gradient(90deg, #e62e04, #f19e38)  !important;
    border: 3px solid transparent !important;
    color:#e62e04 !important; 
    font-weight: 600 !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
}


.shipify-pricing-rules-row-mobile {
  align-items: center;
  font-size: 14px;
  overflow: auto;
}


.shipify-pricing-rules-row-mobile .shipify-stack-item {
    margin-left:0;
    display: flex;
    justify-content: center;
}


.shipify-link-supplier-button-mobile {
    padding: 2px 5px;
}

/* .shipify-supplier-link-btn:hover{
    background:-webkit-linear-gradient(90deg,#e62e04, #f19e38) !important;
    border: 3px solid transparent !important;
    color: white !important;

} */

.shipify-pricing-rules-item{
    margin-left: 0 !important;
    margin-top: 5px !important;
}

.value-input-label.Mui-focused {
    background: white ;
    padding: 0 10px;
}


.shipify-price-input-btn.MuiIconButton-sizeSmall {
    width: 100%;
    height: 50%;
    padding: 0;
    border-radius: 0;
}

.shipify-price-input-btn.MuiIconButton-sizeSmall:nth-child(1) {
    border-bottom: 1px solid #c4c4c4;
}

.shipify-price-input-btn.MuiIconButton-sizeSmall svg{
    width: 18px;
    height: 18px;
    fill: #c4c4c4;
}



.shipify-pricing-rules-data{
    padding-bottom: 20px;
    font-weight: 500;
    color: grey;
}


.MuiDataGrid-cell.MuiDataGrid-withBorderColor {
    max-height: 52px;
}

.shipify-shipping-price-info {
    fill: grey;
    font-size: 18px;
}


.price-rules-table .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor {
    background: #f7f7f7;
    line-height: 40px !important;
    min-height: 40px !important;
    max-height: 40px !important;
}


.shipify-pricing-rules-item .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-outlined.MuiInputAdornment-sizeSmall {
    padding-right: 5px;
}


.shipify-price-rules-note {
    display: flex; 
    align-items: center; 
    gap: 5px; 
    font-weight: 400;
    font-size: 14px;  
    color: orange; 
    position: absolute; 
    top: 140px; 
    padding-left: 8px;
}

.MuiTooltip-tooltip p {
    font-weight: 400;
    margin-bottom: 0;
}

.MuiTooltip-tooltip div > p > svg {
  margin-right: 5px;
}