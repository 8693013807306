.shipify-recommended-resources {
    width: var(--shipify-home-page-health-check-section-width);
}

.mobile-shipify-recommended-resources {
    flex-grow: 1;
}

.shipify-recommended-resources-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    padding: 20px 10px;
}

.shipify-recommended-resources-section:not(:last-child)::after {
    content: "";
    height: 1px;
    width: calc(100% + 50px);
    background-color: #cccccc;
    position: absolute;
    bottom: 0;
    left: -25px;
}

.shipify-recommended-resources-section-link-text {
    color: black;
    font-size: 18px;
    font-weight: 500;
}

.shipify-recommended-resources-section-text {
    color: grey;
    font-size: 15px;
}