.shipify-section {
    padding: 15px 25px;
    border-radius: 15px;
    border: 1px solid #f5f5f5;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}

.shipify-section-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shipify-section-title {
    font-size: 18px;
    color: black;
    font-weight: 500;
}