.loader-button-container {
    position: relative;
}

.loader-button-container.loading,
.loader-button-container.disabled {
    cursor: none;
    pointer-events: none;
}

.loader-button-container.disabled {
    opacity: 0.3;
}

button.loader-button-container {
    border: none;
}

.loader-button-loader-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-button-text {
    width: 100%;
    text-align: center;
}