.mobile-multi-select-status-button,
.multi-select-status-button {
    display: flex;
    gap: 10px;
    cursor: pointer;
    background-color: white;
    padding: 10px 8px;
    justify-content: space-between;
    border-radius: 5px;
    border: 1px solid rgb(223, 225, 236)
}

.mobile-multi-select-status-button {
    border: 1px solid #cccccc;
}

.mobile-multi-select-status-button-title,
.multi-select-status-button-title {
    color: hsl(231, 17%, 39%);
    font-weight: 500;
    flex-shrink: 0;
}

.mobile-multi-select-status-button-title,
.multi-select-status-button-title {
    font-size: 14px;
    display: flex;
    align-items: center;
    padding-left: 5px;
}

.mobile-multi-select-status-button-right-section,
.multi-select-status-button-right-section {
    display: flex;
    align-items: center;
}

.multi-select-status-button-right-section {
    gap: 5px;
}

.mobile-multi-select-status-button-right-section {
    gap: 5px;
}

.mobile-multi-select-status-button-right-section-quantity,
.multi-select-status-button-right-section-quantity {
    color: green;
    font-size: 14px;
}

.multi-select-status-button-right-section-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    fill: green;
}

.status-button-right-section-quantity {
    color: green;
}