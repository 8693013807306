.shipify-banner .banner-img,
.shipify-banner .banner-svg svg {
    width: 50px;
    height: 50px;
}


.shipify-banner.MuiBox-root {
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}


.MuiTypography-root.banner-title {
    font-size: 18px;
    color: black;
    font-weight: 500;
    font-family: 'Rubik', 'sans serif';
}


.MuiButton-root.banner-button {
    font-family: 'Rubik', 'sans serif';
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 10px;
    width: max-content;
    padding: 7px 13px;
    border-radius: 6px;
    color: white;
    border: 0;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%), rgba(48, 48, 48, 1);
    box-shadow: 0rem -0.0625rem 0rem 0.0625rem rgba(0, 0, 0, .8) inset, 0rem 0rem 0rem 0.0625rem rgba(48, 48, 48, 1) inset, 0rem 0.03125rem 0rem 0.09375rem rgba(255, 255, 255, .25) inset;
}

.MuiButton-root.banner-button:hover {
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%), rgba(26, 26, 26, 1);
    box-shadow: 0;
    border: 0;
}


.MuiButton-root.banner-button:active {
    background-color: hsl(0, 0%, 30%);
    box-shadow: 0;
    border: 0;
}


.MuiButton-root.banner-button svg {
    width: 20px;
    height: 20px;
    color: inherit;
    border: 0;
}


.recomended-text-with-link {
    display: flex;
    align-items: center;
    gap: 5px;
}