.mobile-shipping-group-view-container,
.shipping-group-view-container {
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.shipping-group-view-container {
    gap: 15px;
    padding-top: 70px;
}

.mobile-shipping-group-view-container {
    gap: 0;
    padding-bottom: var(--page-bottom-padding);
    padding-top: var(--topbar-height);
}

.mobile-shipping-group-view-content {
    height: 100%;
}

.shipping-group-view-titles-container {
    width: calc(100% - 240px);
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 240px;
    right: 0;
    margin: 0 auto;
    padding: calc(var(--topbar-height) + 1px) 30px 10px 30px;
    background-color: white;
    z-index: 400;
}

.shipping-group-view-titles-container.minimized {
    width: calc(100% - 65px);
    left: 65px;
}

.shipping-group-view-title {
    font-size: 25px;
}

.shipping-group-view-save-button {
    padding: 12px 18px;
    font-size: 13px;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%), rgba(48, 48, 48, 1);
    box-shadow: 0rem -0.0625rem 0rem 0.0625rem rgba(0, 0, 0, .8) inset, 0rem 0rem 0rem 0.0625rem rgba(48, 48, 48, 1) inset, 0rem 0.03125rem 0rem 0.09375rem rgba(255, 255, 255, .25) inset;
}


.shipping-group-view-save-button:hover {
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%) , rgba(26, 26, 26, 1);
}


.shipping-group-view-save-button:active {
    background-color: hsl(0, 0%, 20%);
}