.orders-table-no-items-image {
    width: 40px;
    height: 40px;
}



.shipify-shipping-groups-page .MuiPaper-root > .paydin-table-loader-container > .loader {
    border-left-color: var(--shipify-blue);
    border-right-color: var(--shipify-blue);
    border-bottom-color: var(--shipify-blue);
    width: 30px !important;
    height: 30px !important;
}