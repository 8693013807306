.how-to-use-page-container {
    padding: 20px 0 20dvh 0;
}

.how-to-use-image {
    width: 100%;
    aspect-ratio: 3;
}

.how-to-use-page-text-section {
    width: 80%;
    margin: 0 auto;
    padding: 30px 0;
}

.how-to-use-page-link {
    text-decoration: underline;
}

.how-to-use-page-link:hover {
    text-decoration: underline !important;
}

.how-to-use-page-section-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

ol > .how-to-use-page-ordered-list-item-label {
    font-size: 16px;
    font-weight: 500;
    display: inline;
}