.shipping-methods-edit-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.shipping-methods-edit-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 10px;
}

.shipping-methods-edit-add-shipping-method-button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 3px 7px;
    background-color: white;
    color: green;
    cursor: pointer;
    width: fit-content;
    border: 1.5px solid;
    border-radius: 8px;
}

.shipping-methods-edit-add-shipping-method-button:hover {
    background-color: rgb(0,128,0, 70%);;
    color: white;
}


.shipping-methods-edit-add-shipping-method-button:active {
    background-color: rgb(0,128,0, 15%);
}

.shipping-methods-edit-add-shipping-method-button-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.shipping-methods-edit-add-shipping-method-button-text {
    font-size: 14px;
}