.mobile-multi-select-dialog-content,
.multi-select-dialog-content {
    --add-products-dialog-topbar-height: 90px;
    --add-products-dialog-product-list-header-height: 30px;
    --add-products-dialog-product-list-height: 200px;
    --mobile-add-products-dialog-product-list-height: calc(100dvh - 130px);
    --add-products-dialog-item-height: 105px;
    --mobile-add-products-dialog-item-height: 85px;
    --add-products-dialog-products-content-height: 430px;
    --mobile-add-products-dialog-products-content-height: 440px;

    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.mobile-multi-select-dialog-content {
    gap: 5px;
}

.multi-select-dialog-content {
    display: flex;
    justify-content: space-between;
}

.mobile-multi-select-dialog-details,
.multi-select-dialog-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mobile-multi-select-dialog-topbar,
.multi-select-dialog-topbar {
    display: flex;
    justify-content: space-between;
}

.multi-select-dialog-topbar {
    padding: 10px 10px 0 10px;
}

.mobile-multi-select-dialog-topbar {
    padding: 0 10px;
}

.multi-select-dialog-topbar-done-button {
    color: #0095f6;
    font-weight: 600;
}

.multi-select-dialog-done-button.disabled,
.multi-select-dialog-topbar-done-button.disabled {
    opacity: 0.4;
    pointer-events: none;
}

.mobile-multi-select-dialog-topbar-title-container,
.multi-select-dialog-topbar-title-container {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px 0;
}

.mobile-multi-select-dialog-topbar-title-container {
    justify-content: space-between;
}

.multi-select-dialog-topbar-titles {
    display: flex;
    align-items: center;
    gap: 10px;
}

.multi-select-dialog-topbar-title {
    font-size: 18px;
    font-weight: 500;
}

.multi-select-dialog-topbar-search-box {
    padding: 5px 10px;
    border: 1px solid hsl(225, 18%, 90%);
    display: flex;
    align-items: center;
    gap: 10px;
    width: 300px;
    height: 40px;
    margin: auto 0;
    border-radius: 3px;
}

.multi-select-dialog-topbar-search-box-input {
    border: none;
    outline: none;
    width: 100%;
}

.multi-select-dialog-topbar-search-box-input::placeholder {
    font-size: 15px;
    font-weight: 500;
    color: #b8bcd2;
}

.multi-select-dialog-status-bar {
    width: 100%;
    padding: 5px 10px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    gap: 10px;
}

.multi-select-dialog-topbar-selected-items-count {
    font-size: 14px;
    color: #717171;
}

.multi-select-dialog-done-button-container {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: end;
    padding: 15px;
}

.multi-select-dialog-cancel-button,
.multi-select-dialog-done-button {
    padding: 10px 20px;
    text-transform: capitalize;
    text-align: center;
    width: 200px;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    border-radius: 8px;
}

.multi-select-dialog-done-button {
    color: white;
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%), rgba(48, 48, 48, 1);
    box-shadow: 0rem -0.0625rem 0rem 0.0625rem rgba(0, 0, 0, .8) inset, 0rem 0rem 0rem 0.0625rem rgba(48, 48, 48, 1) inset, 0rem 0.03125rem 0rem 0.09375rem rgba(255, 255, 255, .25) inset;
}

.multi-select-dialog-cancel-button {
    background-color: white;
    color: black;
    border: 1px solid black;
    box-shadow: 0rem -0.0625rem 0rem 0.0625rem rgba(0, 0, 0, .8) inset, 0rem 0rem 0rem 0.0625rem rgba(48, 48, 48, 1) inset, 0rem 0.03125rem 0rem 0.09375rem rgba(255, 255, 255, .25) inset;
}

.multi-select-dialog-items,
.mobile-multi-select-dialog-items {
    position: relative;
}

.multi-select-dialog-items-list,
.multi-select-dialog-items {
    height: var(--add-products-dialog-products-content-height);
}

.mobile-multi-select-dialog-items-list,
.multi-select-dialog-items-list {
    overflow: auto;
    padding: 0 10px;
    width: 100%;
}

.mobile-multi-select-dialog-items-list {
    padding-bottom: 40px;
}

.multi-select-dialog-items-list-container {
    display: flex;
    flex-direction: column;
}

.mobile-multi-select-dialog-items-list-content,
.multi-select-dialog-items-list-content {
    display: grid;
    gap: 10px;
}

.mobile-multi-select-dialog-loader-cell {
    height: 100%;
}

.mobile-multi-select-dialog-loader-cell,
.multi-select-dialog-loader-cell {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 60;
}

.mobile-multi-select-dialog-pagination-loader-cell,
.multi-select-dialog-pagination-loader-cell {
    width: 100%;
    height: 50px;
    position: relative;
    background-color: white;
}

.multi-select-dialog-no-results-found-container {
    width: 100%;
    height: var(--add-products-dialog-products-content-height);
    background-color: white;
}

.multi-select-dialog-no-results-found-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.multi-select-dialog-no-results-found-text {
    font-size: 20px;
    color: #B0B0B0;
    text-align: center;
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    z-index: 40;
}

.multi-select-dialog-limit-reached-alert {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 120;
    inset: 0;
    margin: auto;
}

.multi-select-dialog-limit-reached-alert-message {
    color: white;
    background-color: black;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    text-align: center;
}