.mobile-page-view-topbar,
.page-view-topbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
}

.page-view-topbar {
    padding: 15px 0 10px 0;
}

.mobile-page-view-topbar {
    position: fixed;
    z-index: 300;
    background-color: white;
    top: 0;
    left: 0;
}

.mobile-page-view-topbar::after {
    content: "";
    display: block;
    position: absolute;
    background-color: #cccccc;
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 50;
}

.page-view-title {
    text-align: center;
    font-weight: 500;
}

.page-view-topbar-save-button {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-align: end;
    color: #0095f6;
    padding: 10px;
    width: fit-content;
    margin: 0 0 0 auto;
}

.page-view-topbar-save-button.disabled {
    opacity: 0.5;
    cursor: none;
    pointer-events: none;
}