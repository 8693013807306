.MuiButtonBase-root.shipify-data-button{
    font-size: 11px;
    text-transform: capitalize;
    font-family: 'Rubik', 'sans serif';
    padding: 0;
    border: 1px solid transparent;
    min-width: 40px;
}


.shipify-dates-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
}


.shipify-mini-stats-header-container > .MuiInputBase-root > .MuiSelect-select.MuiSelect-outlined {
    padding: 2px 25px 2px 10px;
    font-size: 13px;
    font-family: 'Rubik', 'sans serif';
    height: 20px;
}

.shipify-mini-stats-header-container > .MuiInputBase-root > .MuiSvgIcon-root {
    right: 0px;
}
    

.shipify-range-item.MuiMenuItem-root{
    font-family: 'Rubik', 'sans serif';
}

.MuiButtonBase-root.shipify-range-item.MuiMenuItem-root.Mui-selected {
    background:  var(--shipify-blue);
    color: white;
    font-weight: 400;
}


.shipify-range-item.MuiMenuItem-root:hover {
    color: var(--shipify-blue);
}