.mobile-rate-container,
.rate-container {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
}

.rate-container {
    justify-content: space-between;
    width: 60%;
    padding: 10px;
}

.mobile-rate-container {
    width: 100%;
    align-items: center;
    padding: 5px 10px;
    position: relative;
    justify-content: space-between;
    gap: 5px;
}

.mobile-rate-container::after {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    background-color: #dedede;
    position: absolute;
    bottom: 0;
    left: 0;
}

.rate-name {
    width: 50%;
}

.mobile-rate-eta,
.rate-eta {
    display: flex;
    align-items: center;
    gap: 5px;
}

.rate-eta {
    width: 30%;
}

.rate-eta-label {
    font-weight: bolder;
}

.rate-price {
    width: 20%;
    text-align: end;
    font-weight: 500;
}

.mobile-rate-titles {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    max-width: 150px;
}

.mobile-rate-name {
    font-size: 15px;
}

.mobile-rate-eta {
    font-size: 12px;
}


.price-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
    flex-wrap: wrap;
}


.mobile-rate-prices {
    width: 40%;
    display: flex;
    flex-direction: column;
    max-width: 120px;
}


.mobile-rate-prices .rate-price {
    width: 100%;
    max-width: fit-content;
}