.store-orders-table-header-view {
    margin: auto;
    padding: 16px;
}

.store-orders-table-header-view-date {
    display: flex;
    gap: 10px;
}

.store-orders-table-header-view-date-image {
    width: 20px;
    height: 20px;
}

.store-orders-table-header-view {
    font-size: 14px;
}

.store-orders-table-header-view-total-price,
.store-orders-table-header-view-total-products-count {
    padding: 5px;
}

.shipping-groups-tabe-header-rates {
    padding: 16px 5px;
}