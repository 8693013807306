.shipify-box{
    border: 1px solid #eaeaea;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}


.MuiAccordionDetails-root > .MuiBox-root:nth-child(2),
.MuiAccordionDetails-root {
    border-top: 1px solid rgba(145, 158, 171, 0.2);
}

.paydin-dialog-message img.MuiBox-root {
    width: 100%;
    padding-top: 15px;
}


.paydin-dialog.image-zoom-accordion {
    display:flex;
    flex-direction: column;
    height: inherit;
    padding: 0 !important;
    min-height: 450px !important;
    overflow: hidden;
}