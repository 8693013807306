.MuiGrid-root.MuiGrid-item.shipify-video-list-wrapper {
    background: #fafafa;
    border-radius: 15px;
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px;
}


.shipify-video-list-wrapper > nav  > div {
    border-bottom: 1px solid #ccc;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 10px;
    align-items: center;
}

.shipify-video-list-wrapper > nav  > .MuiListItemButton-root > div > span {     
    font-family: 'Rubik', 'sans serif'

}

.shipify-video-list-wrapper > nav  > .selected > svg,
.shipify-video-list-wrapper > nav  > .selected > div > span {
    fill:  var(--shipify-blue);
    color: var(--shipify-blue);
    font-weight: 500;
}

.MuiGrid-root > .MuiGrid-item.shipify-video-wrapper {
    padding: 0;
}