.mobile-shipify-products-page-container,
.shipify-products-page-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 15px;
}

.shipify-products-page-container {
    overflow: hidden !important;
}

.mobile-shipify-products-page-container {
    gap: 20px;
    padding: 25px 15px 15px 15px;
}


.shipify-products-page-title {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
}


.shipify-products-page-search {
    width: -webkit-fill-available;
}



.shipify-products-page-search .MuiFormControl-root.MuiTextField-root{
    width: 100% !important;
}

.shipify-products-data-grid .MuiDataGrid-columnHeader:focus, 
.shipify-products-data-grid .MuiDataGrid-cell:focus, 
.shipify-products-data-grid .MuiDataGrid-columnHeader:focus-within,
.shipify-products-data-grid .MuiDataGrid-cell:focus-within,
.shipify-non-edit-cell:focus, 
.shipify-non-edit-cellr:focus-within,
.shipify-non-edit-cell:active  {
    outline: 0px !important;
    box-shadow: none !important;
    background-color: transparent !important;
}


.shipify-products-data-grid.MuiDataGrid-root {
    border-radius: 10px !important;
    border: 1px solid #f5f5f5;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    border-color: #f5f5f5;
}


.shipify-products-data-grid .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor,
.shipify-products-data-grid .MuiDataGrid-columnHeader--moving {
    background-color: #f7f7f7 !important;
    line-height: 40px !important;
    min-height: 40px !important;
    max-height: 40px !important;
}

.shipify-products-data-grid .MuiDataGrid-withBorderColor{
    height: 40px !important;

}

.MuiTablePagination-selectLabel{
    padding: 4px;
    margin: 0;
}


.shipify-non-edit-cell {
    color: rgba(0, 0, 0, 0.54) !important;

}


.shipify-products-page-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}


.shipify-product-image-container div {
    background: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.shipify-drawer-product-image svg {
    width: 90%;
    height: 90%;
    color: rgba(0, 0, 0, 0.54);
}

.shipify-product-image-container {
    width: 50px;
    height: 50px;
}


.shipify-product-image {
    width: inherit;
    display: flex;
    align-items: center;
    max-height: 32px;
}


.shipify-image-cell {
    justify-content: center !important;
}


.shipify-source-cell {
    font-weight: 500 !important;
}

.shipify-drawer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 25px;
}


.shipify-drawer-title {
    font-size: 20px;
    color: black;
    font-weight: 500;
}


.shipify-product-image-container div svg {
    width: 30px;
    height: 30px;
}


.shipify-product-tabs .MuiTabs-flexContainer, 
.shipify-product-tabs-mobile .MuiTabs-flexContainer {
    gap: 20px;
    margin: 0 25px ;
    border-bottom: 1px solid #eee;
}


.shipify-product-tabs-mobile .MuiTabs-flexContainer {
    margin: 0 5px ;
}


.shipify-product-tabs .MuiTabs-flexContainer button,
.shipify-product-tabs-mobile .MuiTabs-flexContainer button {
    text-transform: capitalize !important;
    font-size: 16px;
}


.shipify-product-tabs .MuiTabs-flexContainer button.Mui-selected {
    font-weight: 600 !important;
    color: var(--shipify-blue);
}


.shipify-product-tabs .MuiTabs-indicator {
    background-color: var(--shipify-blue);
}

.shipify-drawer-mapping-box{
    height: calc(90% - 25px);
    overflow: auto;
}

.shipify-drawer-product-image{
    width: 250px;
    height: 250px;
    border-radius: 8px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
}


.shipify-drawer-poduct-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    padding: 15px 25px;
}

.shipify-drawer-product-image img {
    width: 95%;
    max-height: 95%;
}


.shipify-drawer-product-title{
    font-size: 18px;
    color: rgb(90, 90, 90);
    text-align: center;
}


.shipify-drawer-mapping, 
.shipify-drawer-mapping-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px 25px 25px;
}

.shipify-drawer-mapping-mobile {
    padding: 0;
}


.shipify-drawer-mapping-input {
    width: 100% !important;
    border:1px solid transparent;
    border-radius: 8px !important;
}

.shipify-drawer-mapping-input:hover,
.shipify-drawer-mapping-input:active,
.shipify-drawer-mapping-input:focus
 {
    border:1px solid var(--shipify-blue);
}


.drawer-map-title {
    font-weight: 500;
    color: rgb(90, 90, 90);
    display: flex;
    align-items: center;
    gap: 10px;
}


.drawer-map-save-btn.MuiLoadingButton-root {
    background: var(--shipify-blue) !important;
    color: white;
    border-radius: 8px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}


.drawer-map-save-btn.MuiLoadingButton-root:disabled{
    background-color: rgb(5 140 238 / 64%) !important;
    color: white
}

.drawer-map-save-btn.MuiLoadingButton-loading,
.drawer-map-save-btn.MuiLoadingButton-loading:disabled {
    color: transparent !important;
}

.shipify-products-view-btn {
    color: rgb(90, 90, 90) !important;
    text-transform: capitalize !important;
}

.shipify-products-link-btn {
    text-transform: capitalize !important;
}

.shipify-source-container{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 17px;
}

.shipify-drawer-poduct-shipping,
.shipify-drawer-poduct-variants{
    padding: 15px;
}


.MuiDataGrid-cell.MuiDataGrid-withBorderColor {
    height: 70px !important;
}

.shipify-drawer-shipping-methods-container-mobile,
.shipify-drawer-shipping-methods-container{
    display: flex;
    gap: 20px;
    background: #fafafa;
    height: 600px;
    border-radius: 8px;
}


.shipify-drawer-shipping-methods-container-mobile {
    display: block;
    height: 100%;
}

.shipify-drawer-shipping-methods-container-mobile .shipify-drawer-countries-block,
.shipify-drawer-shipping-methods-container-mobile .shipify-drawer-shipping-details-block {
    width: 100%;
    text-align: center;
    margin-bottom: 35px;
    background: white;
}

.shipify-drawer-countries-block{
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.shipify-drawer-shipping-details-block{
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
}

ul.MuiList-root.MuiList-padding.shipify-drawer-country-list {
    max-height: 600px !important;
    overflow: auto !important;
    overflow-x: hidden !important;
    border-bottom-left-radius: 8px;
}


.shipify-ship-country {
    height: 40px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    font-weight: 500;
}


.shipify-drawer-shipping-title {
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
}


.shipify-country-item {
    background: #fafafa;
    height: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
}


.shipify-country-item span {
    font-size: 14px;
}


.shipify-country-item.MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters:nth-child(2n) {
    background: #f5f5f5;    
}


.shipify-country-item.MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters:hover {
    background: #eee;
    color: var(--shipify-blue);

}

.shipify-country-item.selectedItem {
    background: #eee !important;
    color: var(--shipify-blue);
    font-weight: 500;
}

.shipify-drawer-mapping-box::-webkit-scrollbar,
.shipify-drawer-country-list::-webkit-scrollbar {
    width: 5px;
  
}


.shipify-drawer-mapping-box::-webkit-scrollbar-track,
.shipify-drawer-country-list::-webkit-scrollbar-track {
    background: #f1f1f1; 
}


.shipify-drawer-mapping-box::-webkit-scrollbar-thumb,
.shipify-drawer-country-list::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
    height: 40px;
  
}
  

.shipify-drawer-mapping-box::-webkit-scrollbar-thumb:hover,
.shipify-drawer-country-list::-webkit-scrollbar-thumb:hover {
    background: #555; 
    border-radius: 10px;
}


.shipify-drawer-countries-block .MuiFormControl-root .MuiInputBase-root {
    border-radius: 8px;
}


.css-18lrjg1-MuiCircularProgress-root {
    animation-duration: 450ms !important;
}

.MuiAccordionSummary-content,
.MuiAccordionSummary-content.Mui-expanded {
    margin: 0 !important;
    gap: 15px;
    justify-content: space-between;
    padding-right: 0;
}

.shipify-products-data-grid .MuiDataGrid-cell.MuiDataGrid-withBorderColor{
    min-height: 70px ;
}


.base-NumberInput-input {
    min-width: 100px;
}


.base-NumberInput-root::before {
    content: "Quantity";
    position: absolute;
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: translate(5px, -17px) scale(0.75);
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    background: white;
    padding: 0 10px 0 5px;
}


.base-NumberInput-root:focus-within::before {
    color:var(--shipify-blue);
}


.shipify-alert-link {
    color: inherit;
    text-decoration: underline;
    font-weight: 400;
    cursor: pointer;
}


.MuiCircularProgress-root.MuiCircularProgress-colorPrimary {
    animation: spin 500ms infinite linear !important;
    color: transparent;
    width: 30px !important;
    height: 30px !important;
    border: 2px solid;
    border-radius: 50%;
    border-top-color: transparent;
    border-color: transparent var(--shipify-blue) var(--shipify-blue) transparent;
}


.MuiCircularProgress-root > svg {
    display: none;
}


@keyframes spin {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: 360deg;
    }
}


@keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  }


  .mapping-country-input-pulse {
    animation: pulse-animation 2s infinite;
    border-radius: 4px;
  }

  .mapping-country-input-pulse > .MuiFormLabel-root {
    background: white;
  }


.MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}


.loader-button-loader-container > .loader {
    border-color: transparent var(--shipify-blue) var(--shipify-blue) transparent !important;
}


.drawer-map-save-btn span > span > svg {
    display: block;
    color: white;
}


.MuiButtonBase-root.shipify-products-link-btn {
    color: var(--shipify-blue);
}


.MuiDrawer-root.MuiDrawer-modal {
    z-index: 1300;
}

.shipify-products-page-content > .MuiPaper-root {
    border: 1px solid #f5f5f5;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}


.MuiPaper-root.MuiPaper-elevation.MuiMenu-paper {
    max-height: 400px;
}


.shipify-products-page-content-section {
    width: 34%;
}


.shipify-products-page-content-section .shipify-data-section-data {
    gap: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
    

.shipify-products-page-content .shipify-home-page-content-row {
    margin-bottom: 15px;
}



.shipify-drawer-mapping .MuiAlertTitle-root {
    font-weight: 600;
}


.shipify-drawer-poduct-details  .shipify-supplier-banner {
    margin-top: 15px;
    padding: 15px 10px;
    background: rgb(255, 244, 229);
}


.shipify-drawer-poduct-details  .shipify-supplier-banner .MuiPaper-root {
    background: transparent;
}
    
.mapping-input-country {
    margin-left: 10px;
}


