.user-strip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cccccc;
    padding: 10px;
    cursor: pointer;
}

.user-strip:active,
.user-strip:hover {
    border: 1px solid var(--shipify-blue);
}

.user-strip-data {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-strip-username {
    color: grey;
    font-size: 15px;
}

.user-menu-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    width: 100%;
}

.user-menu-button {
    background-color: #f8f9fb;
    border: 1px solid #e3e4e6;
    color: #474f55;
    padding: 10px 15px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.user-menu-button:hover {
    color: var(--shipify-blue);
    border-color: var(--shipify-blue);
}