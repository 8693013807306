.mobile-shipify-home-page-container,
.shipify-home-page-container {
    --shipify-home-page-health-check-section-width: calc(34%);
    
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px 10px 10px;
}

.mobile-shipify-home-page-container.free-subscription,
.shipify-home-page-container.free-subscription {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-shipify-home-page-container.free-subscription {
    height: calc(100vh - var(--mobile-dashboard-topbar-height) - 40px);
}

.shipify-home-page-container.free-subscription {
    height: calc(100vh - var(--topbar-height) - 40px);
}

.mobile-shipify-home-page-container {
    padding: 20px 10px 10px;
}

.shipify-home-page-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.shipify-home-page-content-row {
    display: flex;
    gap: 15px;
}

.shipify-home-page-health-check-todo-row {
    min-height: 400px;
}

.shipify-home-page-content-column {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.shipify-home-page-content-section {
    width: var(--shipify-home-page-health-check-section-width);
    max-width: var(--shipify-home-page-health-check-section-width);

}

.mobile-shipify-home-page-content-section {
    flex-grow: 1;
}

.shipify-home-page-content-health-check-section {
    width: var(--shipify-home-page-health-check-section-width);
}

.mobile-shipify-home-page-content-health-check-section {
    width: 100%;
}

.shipify-home-page-content-todo-section {
    width: calc(100% - var(--shipify-home-page-health-check-section-width));
}

.mobile-shipify-home-page-content-todo-section {
    width: 100%;
}

.mobile-shipify-home-page-content-section-image,
.shipify-home-page-content-section-image {
    width: 70px;
    height: 70px;
    flex-shrink: 0;
    fill: var(--shipify-blue);
}

.mobile-shipify-home-page-content-section-image {
    width: 70px;
    height: 70px;
}

.shipify-home-page-task-image {
    margin-top: -20px;
    width: 190px;
    height: 100px;
}

.shipify-guide-section {
    width: var(--shipify-home-page-health-check-section-width);
}

.mobile-shipify-guide-section {
    flex-grow: 1;
}

.shipify-guide-link {
    padding: 10px 0;
}

.shipify-guide-link-text,
.shipify-guide-link-image {
    color: #2b69b9;
    font-size: 18px;
    font-weight: 500;
}

.shipify-guide-image {
    width: 100%;
    aspect-ratio: 2;
    margin-bottom: 10px;
}


.shipify-supplier-banner {
    width: 100%;
}

.banner-button .MuiCircularProgress-root > svg {
    display: block;
    color: white !important;
}


.activate-dialog {
    min-height: 200px;
    gap: 0;
    overflow: hidden !important;
}


.activate-dialog > .paydin-dialog-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.dots {
    display: inline-block;
  }
  
  .dots span {
    animation: blink 1s infinite step-start 0.2s;
    opacity: 0;
  }
  
  .dots span:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dots span:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .dots span:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  


  .activate-dialog .activating-text {
    max-height: 50px;
    margin-bottom: 0;
    margin-top: 15px;
  }