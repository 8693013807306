.base-layout-container {
    display: flex;
    width: 100%;
}

.mobile-base-layout-content-container,
.base-layout-content-container {
    width: 100%;
    height: 100%;
    margin: auto;
    padding-inline: 20px;
    padding-bottom: 20px;
}

.mobile-base-layout-content-container {
    padding-inline: 5px;
}

.base-layout-content-container {
    padding-top: var(--topbar-height);
}

.mobile-base-layout-content-container {
    padding-top: var(--mobile-dashboard-topbar-height);
}