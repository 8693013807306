.shipping-method-row-edit-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.shipping-method-row-edit-container:not(:first-child){
    padding-top: 15px;
    border-top: 1px solid #eaeaea;
}

.mobile-shipping-method-row-edit,
.shipping-method-row-edit {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    overflow: auto;
    padding-bottom: 5px;
}


.mobile-shipping-method-row-edit::-webkit-scrollbar,
.shipping-method-row-edit::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}


.mobile-shipping-method-row-edit::-webkit-scrollbar-track,
.shipping-method-row-edit::-webkit-scrollbar-track {
    background: #f1f1f1; 
}


.mobile-shipping-method-row-edit::-webkit-scrollbar-thumb,
.shipping-method-row-edit::-webkit-scrollbar-thumb {
    background: lightgray; 
    border-radius: 10px;
    height: 40px;
  
}
  

.mobile-shipping-method-row-edit::-webkit-scrollbar-thumb:hover,
.shipping-method-row-edit::-webkit-scrollbar-thumb:hover {
    background: gray; 
    border-radius: 10px;
}



.mobile-shipping-method-row-edit {
    flex-direction: column;
}

.shipping-method-button-row {
    display: flex;
}

.shipping-method-row-edit-remove-button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 3px 7px;
    background-color: white;
    color: red;
    margin-left: auto;
    cursor: pointer;
    border: 1.5px solid;
    border-radius: 8px;
}

.shipping-method-row-edit-remove-button:hover {   
    background-color: rgb(255 ,0 ,0, 70%);
    color: white;
}


.shipping-method-row-edit-remove-button:active {
    background-color: rgb(255 ,0 ,0, 15%);
}

.shipping-method-row-edit-remove-button-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.shipping-method-row-edit-remove-button-text {
    font-size: 14px;
}