
.loader {
    width: 30px;
    height: 30px;
    border: 2px solid;
    border-top-color: transparent;
    border-color: transparent var(--shipify-blue) var(--shipify-blue) transparent;
    border-radius: 50%;
    animation: spin 500ms infinite linear;
}

@keyframes spin {
    from {
        rotate: 0deg;
    }
    to {
        rotate: 360deg;
    }
}