.mobile-shipify-settings-and-plans-page-container,
.shipify-settings-and-plans-page-container {
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: 15px;
}

.mobile-shipify-settings-and-plans-page-container {
    padding-top: 25px;
}

.shipify-settings-and-plans-page-title {
    font-size: 30px;
    /* font-weight: 600; */
}

.shipify-settings-and-plans-page-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.shipify-settings-and-plans-page-buttons-section {
    width: 100%;
}

.shipify-settings-and-plans-page-save-button {
    width: fit-content;
    padding: 7px 13px;
    color: white;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    margin-left: auto;
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%), rgba(48, 48, 48, 1);
    box-shadow: 0rem -0.0625rem 0rem 0.0625rem rgba(0, 0, 0, .8) inset, 0rem 0rem 0rem 0.0625rem rgba(48, 48, 48, 1) inset, 0rem 0.03125rem 0rem 0.09375rem rgba(255, 255, 255, .25) inset;
}


.shipify-settings-and-plans-page-save-button:hover {
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%) , rgba(26, 26, 26, 1);
}


.shipify-settings-and-plans-page-save-button:active {
    background-color: hsl(0, 0%, 30%);
}


.shipify-settings-and-plans-page-content .account-page-section-title{
    color: #303030
}


.shipify-settings-and-plans-page-content > .card-container {
    border-radius: 15px;
    border: 1px solid #f5f5f5;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}


.shipify-plan-section {
    display: flex;
    flex-direction: row;
    gap: 50px;
    flex-wrap: wrap;
}


.shipify-plan-details {
    display: flex;
    gap: 10px;
    flex-direction: column;
    min-width: 100px;
    align-items: flex-start;
}


.shipify-plan-details span {
    font-size: 14px;
    color: #303030;
    font-weight: 400;
}


.shipify-plan-details span:nth-child(2) {
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
}

.shipify-plan-details span:nth-child(2) span {
    font-size: 12px;
}


.continue-sell-switch-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.continue-sell-switch-wrapper svg {
    color: grey;
    font-size: 18px;
}


.shipify-settings-and-plans-page-container .paydin-switch-container {
    cursor: unset;
}

.shipify-settings-and-plans-page-container .paydin-switch-label {
    cursor: pointer;
}

.aliexpress-account { 
    min-width: 185px
}

.MuiBadge-badge.MuiBadge-standard {
    height: 12px;
    min-width: 10px;
}

.shipify-banner-btn-wrapper {
    padding: 8px;
}