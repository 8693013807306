.tooltip-container {
    --tooltip-bubble-width: 220px;
    --tooltip-image-dimensions: 20px;
    
    width: var(--tooltip-image-dimensions);
    height: var(--tooltip-image-dimensions);
    position: relative;
    cursor: pointer;
}

.tooltip-image {
    width: var(--tooltip-image-dimensions);
    height: var(--tooltip-image-dimensions);
    position: absolute;
    inset: 0;
    margin: auto;
    border-radius: 50%;
    background-color: hsl(210, 16%, 90%);
    padding: 5px;
}

.tooltip-container:hover > .tooltip-bubble,
.tooltip-container:hover > .mobile-tooltip-bubble {
    display: block;
}

.mobile-tooltip-bubble,
.tooltip-bubble {
    display: none;
    font-size: 14px;
    color: #EFF0F3;
    padding: 10px;
    position: absolute;
    background-color: hsl(233, 27%, 25%);
    border-radius: 7px;
    width: var(--tooltip-bubble-width);
    z-index: 80;
}

.mobile-tooltip-bubble,
.mobile-tooltip-bubble.horizontal-swap {
    bottom: calc(var(--tooltip-image-dimensions) + 15px);
}

.mobile-tooltip-bubble {
    left: -15px;
    right: unset;
}

.mobile-tooltip-bubble.horizontal-swap {
    left: unset;
    right: -15px;
}

.tooltip-bubble {
    left: calc(var(--tooltip-image-dimensions) + 10px);
    top: -10px;
}

.mobile-tooltip-bubble::before,
.tooltip-bubble::before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    rotate: 45deg;
    position: absolute;
    padding: 10px;
    background-color: hsl(233, 27%, 25%);
    z-index: -1;
}

.tooltip-bubble::before {
    left: -8px;
    top: 10px;
}

.mobile-tooltip-bubble.horizontal-swap::before,
.mobile-tooltip-bubble::before {
    bottom: -10px;
}

.mobile-tooltip-bubble::before {
    left: 13px;
    right: unset;
}

.mobile-tooltip-bubble.horizontal-swap::before {
    right: 17px;
    left: unset;
}