.todo-list-title {
    font-size: 18px;
    color: black;
    font-weight: 500;
    padding-bottom: 15px;
}

.todo-list-task-header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
}

.todo-list-task-header-checked-mark,
.todo-list-task-header-unchecked-mark {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
}

.todo-list-task-header-unchecked-mark {
    border-radius: 50%;
    border: 2px dotted #686666;
}

.todo-list-task-header-title {
    font-weight: 500;
    font-size: 16px;
}

.mobile-todo-list-task-content,
.todo-list-task-content {
    padding-left: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.mobile-todo-list-task-content {
    padding-left: 10px;
}

.mobile-todo-list-task-details,
.todo-list-task-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}

.todo-list-task-details-text {
    font-size: 14px;
}

.todo-list-task-details {
    padding-inline-end: 30px;
}

.todo-list-task-content-description {
    font-size: 13px;
}

.todo-list-task-content-button {
    width: max-content;
    padding: 5px 15px;
    border-radius: 7px;
    color: white;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%), rgba(48, 48, 48, 1);
    box-shadow: 0rem -0.0625rem 0rem 0.0625rem rgba(0, 0, 0, .8) inset, 0rem 0rem 0rem 0.0625rem rgba(48, 48, 48, 1) inset, 0rem 0.03125rem 0rem 0.09375rem rgba(255, 255, 255, .25) inset;
}


.todo-list-task-content-button:hover {
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%) , rgba(26, 26, 26, 1);
}


.todo-list-task-content-button-with-image {
    display: flex;
    align-items: center;
    gap: 10px;
}

.todo-list-buttons-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.todo-list-task-content-button-image {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
}

.todo-list-task-content-button:active {
    background-color: hsl(0, 0%, 35%);
}

.todo-list-counter p {
    font-size: 13px;
    color: #303030;
    margin: 0;
}

.todo-list-counter {
    border: 1px solid #e3e3e3;
    display: flex;
    padding: 3px 6px;
    width: 120px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 10px;
}


.todo-list-desc .desc-text {
    margin-bottom: 10px;
}