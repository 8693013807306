.mobile-edit-link-section-input-container,
.edit-link-section-input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.edit-link-section-input-container {
    height: 100%;
}

.edit-link-section-input-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.edit-link-section-link-data-input-title-info-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.edit-link-section-input-title {
    font-size: 14px;
    color: #7f859e;
}

.edit-link-section-link-data-input-character-limit {
    font-size: 14px;
    font-weight: 300;
}

.edit-field-section-input-input-with-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    flex-grow: 1;
}

.edit-link-section-input-with-prefix,
.edit-field-section-input-input-container {
    height: 40px;
    width: 100%;
    border: 1px solid hsl(231, 24%, 90%);
    padding: 5px 10px;
    border-radius: 5px;
    outline: none;
    background-color: white;
}

.edit-field-section-input-input {
    background-color: transparent;
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
}

/* Chrome, Safari, Edge, Opera */
.edit-field-section-input-input::-webkit-outer-spin-button,
.edit-field-section-input-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.edit-field-section-input-input[type=number] {
  -moz-appearance: textfield;
}

.edit-field-section-input-input::placeholder {
    color: #6B718E !important;
}

.edit-field-section-input-validation-container {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    position: relative;
}

.edit-field-section-input-validation-indicator {
    width: 25px;
    height: 25px;
}

.edit-field-section-input-helper-text {
    font-size: 15px;
    margin-inline-start: 20px;
    color: red;
}

.edit-field-section-input-input::placeholder {
    color: #DCDEE7;
    font-weight: 400;
}

.edit-field-section-input-input-container {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.edit-field-section-input-input-container.enlarged {
    height: 50px;
}

.edit-field-section-input-input-container.multiline {
    height: 100%;
    border: none;
    background-color: transparent;
}

textarea.edit-field-section-input-input::placeholder {
    color: #9f9f9f;
}

.mobile-edit-field-section-input-prefix-row,
.edit-field-section-input-prefix-row {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.edit-field-section-input-prefix-row {
    flex-direction: row;
}

.mobile-edit-field-section-input-prefix-row {
    flex-direction: column;
}

.mobile-edit-field-section-input-prefix,
.edit-field-section-input-prefix,
.embedded-prefix {
    flex-shrink: 0;
}

.mobile-edit-field-section-input-prefix,
.edit-field-section-input-prefix {
    font-size: 14px;
}

.edit-field-section-input-prefix {
    align-self: center;
}

.mobile-edit-field-section-input-prefix {
    align-self: start;
}

.embedded-prefix {
    font-size: 16px;
    color: #6B718E;
    align-self: center;
}

.edit-link-section-link-data-shopping-url-input-container {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.edit-field-section-input-input-prefix-container {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    height: 100%;
}