.mobile-upgrade-your-plan,
.upgrade-your-plan {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #f1f1f1;
    border-radius: 25px;
    padding: 20px;
}

.mobile-upgrade-your-plan {
    flex-direction: column;
    gap: 50px;
}

.upgrade-your-plan-left {
    width: 50%;
}

.mobile-upgrade-your-plan-right,
.mobile-upgrade-your-plan-left {
    width: 100%;
}

.upgrade-your-plan-right {
    width: 50%;
}

.upgrade-your-plan-titles-container {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
}

.upgrade-your-plan-title {
    font-size: 10px;
    font-weight: 500;
}

.upgrade-your-plan-subtitle {
    font-size: 30px;
}

.mobile-upgrade-your-plan-plan-card,
.upgrade-your-plan-plan-card {
    border: 2px solid black;
    border-radius: 10px;
    padding: 70px 50px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    width: fit-content;
    margin-left: auto;
}

.mobile-upgrade-your-plan-titles-container,
.mobile-upgrade-your-plan-plan-card {
    margin: auto;
}

.mobile-upgrade-your-plan-plan-card {
    padding: 40px;
}

.upgrade-your-plan-plan-title {
    font-size: 22px;
    font-weight: 500;
}

.upgrade-your-plan-plan-features {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.upgrade-your-plan-plan-feature {
    display: flex;
    align-items: center;
    gap: 8px;
}

.upgrade-your-plan-plan-feature-check {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    color: lightgreen;
}

.upgrade-your-plan-plan-feature-text {
    color: grey;
    font-size: 14px;
}

.upgrade-your-plan-lower-section {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.upgrade-your-plan-plan-price-trial-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.upgrade-your-plan-free-trial {
    color: grey;
    font-size: 14px;
}

.upgrade-your-plan-plan-price-container {
    display: flex;
    align-items: baseline;
    gap: 5px;
    font-size: 18px;
}

.upgrade-your-plan-plan-price {
    font-weight: 600;
}

.upgrade-your-plan-upgrade-button {
    padding: 12px 15px;
    border-radius: 25px;
    font-size: 13px;
    color: white;
	background: linear-gradient(270.01deg,#9eebd4 .01%,#447ff7);
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.upgrade-your-plan-plan-fees {
    color: grey;
    font-size: 13px;
    padding-left: 5px;
}