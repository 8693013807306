p.MuiTypography-root,
span.MuiTypography-root {
    font-family: 'Rubik', 'sans serif'
}

.shipify-section.shipify-mini-stats > .shipify-data-section-container {
    display: block;

}

    
.shipify-section.shipify-mini-stats > .shipify-data-section-container > .shipify-data-section-custom {
    display: flex;
    flex-direction: column;
    gap: 15px;
}


.shipify-mini-stats > .shipify-data-section-container > .shipify-data-section-data {
    display: none;
}

.shipify-mini-stats-container > div {
    padding-top: 0px;
    align-items: center;
}

.shipify-mini-stats-container > div:nth-child(3) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-left: 15px; 
}


.shipify-mini-stats-header-container .MuiFormControlLabel-root {
    margin: 0;
    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.shipify-mini-stats-header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 30px;
    gap: 20px;
    flex-wrap: wrap;
}

.MuiFormControlLabel-root > .MuiTypography-root.MuiFormControlLabel-label {
    font-size: 13px;
    width: max-content;
}


.MuiFormControlLabel-root > .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0;
}
    
.MuiFormControlLabel-root > .MuiButtonBase-root.MuiCheckbox-root > svg {
    font-size: 20px;
}

.shipify-mini-stats-container {
    display: flex;
    flex-wrap: wrap;
}

/* .MuiGrid-root.MuiGrid-item div > canvas {
    min-width: 100px;
    min-height: 100px;
} */

.shipify-mini-stats-container > .MuiGrid-root > .shipify-chart-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
}

.shipify-stats-label-wrapper {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}


.shipify-stats-label-wrapper > div {
    width: 100%;
    justify-content: space-between;
    color: rgb(90, 90, 90);
}


.shipify-stats-label-wrapper > div > p {
    font-weight: 500;
}

.shipify-stats-label-wrapper > div > div > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}


.stats-label {
    display: flex;
    align-items: center;
    max-width: 65%;
}

.MuiTooltip-tooltip.MuiTooltip-tooltipArrow {
    margin-top: 0;
}


.shipify-mini-stats-container > .MuiGrid-root > .MuiTypography-root > .shipify-value-label {
    font-size: 14px;
    color: rgb(90, 90, 90);
    margin-left: 8px;
    line-height: 1.5;
    font-weight: 500;
}


.shipify-mini-stats-header-container > .shipify-data-section-title {
    display: flex;
    align-items: center;
    gap: 5px;
}

.shipify-mini-stats-header-container > .shipify-data-section-title > span > svg {
    color: grey;
    font-size: 15px;
}

.stats-rev-tooltip > .stats-rev-tooltip-line {
    font-size: 14px;
    justify-content: space-between;
    display: flex;
    align-items: baseline;
    margin-bottom: 5px;
}

.stats-rev-tooltip > .stats-rev-tooltip-line > span {
    font-size: 12px;
    padding-right: 10px;
}
