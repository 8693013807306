.shipify-health-check-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
}

.shipify-health-check-subsection {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.shipify-health-check-subsection::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background-color: #cccccc;
    position: absolute;
    bottom: 0;
    left: 0;
}

.shipify-health-check-check-button {
    background-color: transparent;
    padding: 5px 10px;
    border-radius: 8px;
}

.shipify-health-check-check-button-content {
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: 14px;
    color: var(--shipify-blue);
    font-weight: 500;
    cursor: pointer;
}

.shipify-health-check-check-button-image {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
}


.shipify-health-check-subsection-title.with-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;    
}


.shipify-health-check-subsection-title.with-buttons > .loader-button-container{
    width: max-content;
    padding: 5px 15px;
    border-radius: 7px;
    color: white;
    font-size: 11px;
    text-align: center;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%), rgba(48, 48, 48, 1);
    box-shadow: 0rem -0.0625rem 0rem 0.0625rem rgba(0, 0, 0, .8) inset, 0rem 0rem 0rem 0.0625rem rgba(48, 48, 48, 1) inset, 0rem 0.03125rem 0rem 0.09375rem rgba(255, 255, 255, .25) inset;

}


.shipify-health-check-subsection-title.with-buttons > .loader-button-container:hover {
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%), rgba(26, 26, 26, 1);
}


.shipify-health-check-subsection-title.with-buttons > .loader-button-container:active {
    background-color: hsl(0, 0%, 35%);
}