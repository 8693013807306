    
.shipify-supplier-link-row{
    align-items: center;
    display: flex;
    flex-direction: row;
}


.shipify-link-supplier-button, .shipify-link-supplier-button-mobile {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 7px 13px;
    background-color: #65c466;
    cursor: default;
    width: -moz-fit-content;
    width: fit-content;
    outline: 1px solid #65c466;
    border-radius: 6px;
    color: white;
    min-width: 155px;
    height: 38px;
    font-weight: 500;
    justify-content: center;
}

.shipify-link-supplier-button-text {
    font-size: 14px;
}

.shipify-link-supplier-button-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}


.account-page-section-content{
    padding: 0 15px;
}

.shpify-supplier-link-img {
    margin-left: 10px;
}


.supplier-logo-svg {
    width: 25px;
    height: 25px;
}

.shipify-supplier-link-btn{
    border-radius: 8px !important;
    background: -webkit-linear-gradient(white, white) padding-box,
    linear-gradient(90deg, #e62e04, #f19e38)  !important;
    border: 3px solid transparent !important;
    color:#e62e04 !important; 
    font-weight: 600 !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
}


.shipify-supplier-link-row-mobile {
  align-items: center;
  font-size: 14px;
  overflow: auto;
}


.shipify-supplier-link-row-mobile .shipify-stack-item {
    margin-left:0;
    display: flex;
    justify-content: center;
    margin-top: 5px !important;
}


.shipify-link-supplier-button-mobile {
    padding: 2px 5px;
}

/* .shipify-supplier-link-btn:hover{
    background:-webkit-linear-gradient(90deg,#e62e04, #f19e38) !important;
    border: 3px solid transparent !important;
    color: white !important;

} */


