.mobile-todo-update-rate-task-fields-row,
.todo-update-rate-task-fields-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.mobile-todo-update-rate-task-fields-row {
    flex-direction: column;
}