.dashboard-topbar-container,
.mobile-dashboard-topbar-container {
    --mobile-dashboard-topbar-right-section-button-height: 60px;

    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 300;
}

.dashboard-topbar-container {
    display: none;
}

.mobile-dashboard-topbar-paylink-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    height: var(--mobile-dashboard-topbar-titles-section);
    padding-inline: 15px;
}

.mobile-dashboard-topbar-content {
    padding: 10px 10px 0 10px;
    border-bottom: 1px solid #efefef;
}

.mobile-dashboard-topbar-paylink-logo-container {
    display: flex;
    align-items: center;
    gap: 15px;
}

.mobile-dashboard-topbar-paylink-logo {
    width: 35px;
    height: 35px;
}

.mobile-dashboard-topbar-add-link-button {
    width: 25px;
    height: 25px;
}

.mobile-dashboard-topbar-add-influencer-button {
    width: 20px;
    height: 20px;
}

.mobile-dashboard-topbar-right-section {
    display: flex;
    align-items: center;
    position: relative;
    gap: 5px;
}

.mobile-dashboard-topbar-add-options-menu {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 0 5px 3px #cccccc;
    position: absolute;
    top: var(--mobile-dashboard-topbar-right-section-button-height);
    right: 10px;
    z-index: 90;
}

.mobile-dashboard-topbar-add-options-menu-option {
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: inherit;
}

.mobile-dashboard-topbar-add-options-menu-option:active {
    background-color: hsl(0, 0%, 90%);
}

.mobile-dashboard-topbar-add-button {
    padding: 0 6px;
    width: 40px;
    height: var(--mobile-dashboard-topbar-right-section-button-height);
}

.mobile-dashboard-topbar-add-options-menu-option-image {
    width: 15px;
    height: 15px;
}

.dashboard-topbar-content {
    height: var(--dashboard-topbar-height);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.dashboard-topbar-bottom-line {
    background-color: #cccccc;
    height: 1px;
    width: 100%;
}

.mobile-dashboard-topbar-buttons-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 8px 8px 0 8px;
}

.mobile-dashboard-topbar-button-image {
    width: 32px;
    height: 32px;
    fill: rgb(90,90,90);
}

.mobile-dashboard-topbar-button-image.disabled > path {
    fill: hsl(69, 7%, 80%);
}

.dashboard-button,
.mobile-dashboard-button {
    color: rgb(90,90,90);
    fill: rgb(90,90,90);
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.mobile-dashboard-topbar-user-circle-button {
    width: 27px;
    height: 27px;
}

.mobile-dashboard-topbar-user-circle-image {
    width: 35px;
    height: 35px;
}

.mobile-dashboard-button {
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    padding: 5px 8px;
    height: 60px;
    border-bottom: 2px solid transparent;
}

.mobile-dashboard-topbar-links-button-label {
    font-size: 13px;
    white-space: pre-line;
    text-align: center;
    width: 100%;
}

.mobile-dashboard-button.disabled {
    color: hsl(69, 7%, 80%);
    cursor: none;
    pointer-events: none;
}
.mobile-dashboard-button.disabled > .mobile-dashboard-topbar-button-image {
    fill: hsl(69, 7%, 80%);
}

.mobile-dashboard-button.selected {
    color: var(--shipify-blue);
    fill: var(--shipify-blue);
    border-bottom: 2px solid var(--shipify-blue);
}

.mobile-dashboard-button.selected > .mobile-dashboard-topbar-button-image {
    color: var(--shipify-blue);
    fill: var(--shipify-blue);
}

.mobile-dashboard-topbar-more-options-drawer-option-logout {
    color: red;
}

.mobile-dashboard-topbar-shipping-groups-button-image {
    fill: rgb(90,90,90);
}

.mobile-dashboard-topbar-user-circle-button-username-initials-cirlce {
    background-color: #616c6e;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.mobile-dashboard-topbar-store-name {
    color: grey;
}

.mobile-dashboard-topbar-how-to-use-button-label {
    font-size: 12px;
}