.mobile-account-page-section,
.account-page-section {
    --section-title-color: #525876;
    --subsection-input-background-color: #F9FBFC;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    padding: 10px;
    position: relative;
}

.account-page-section-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 15px;
}

.account-page-section {
    padding: 10px;
}

.mobile-account-page-section {
    width: 100%;
    padding-bottom: 30px;
    box-shadow: 0 0 5px 1px #cccccc;
    border-radius: 10px;
}

.account-page-section-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
}

.account-page-section-title {
    text-align: start;
    font-size: 20px;
    font-weight: 500;
    color: #525876;
}

.account-page-section-buttons-container {
    display: flex;
    justify-content: end;
    width: 100%;
}

.account-page-section-right-top-corner-content {
    display: flex;
    align-items: center;
    gap: 5px;
}