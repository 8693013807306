.mobile-shipify-shipping-comp-page-container,
.shipify-shipping-comp-page-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 15px;
}


.mobile-shipify-shipping-comp-page-container {
    gap: 20px;
    padding: 25px 15px 15px 15px;
}

.mobile-shipify-shipping-comp-container {
    padding-top: 25px;
}

.shipify-shipping-comp-page-title {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
}

.shipify-shipping-comp-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.shipify-shipping-comp-search {
    width: -webkit-fill-available;
}

.shipify-shipping-comp-buttons{
    width: fit-content;
    text-align: right;
    display: flex;
}

.shipify-bulk-edit-button {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    max-height: 35px;
    min-width: 200px;
    max-width: 250px;
}


.shipify-shipping-comp-search .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
}

.shipify-shipping-comp-data-grid .MuiDataGrid-columnHeader:focus, .shipify-shipping-comp-data-grid .MuiDataGrid-cell:focus,
.shipify-shipping-comp-data-grid .MuiDataGrid-columnHeader:focus-within, .shipify-shipping-comp-data-grid .MuiDataGrid-cell:focus-within {
    outline: 0px !important;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    background-color: #fff;
}


.shipify-shipping-comp-data-grid {
    border-radius: 10px !important;
    border: 1px solid #f5f5f5;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    border-color: #f5f5f5;
}

.shipify-shipping-comp-data-grid .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor {
    background: #f7f7f7;
    line-height: 40px !important;
    min-height: 40px !important;
    max-height: 40px !important;
}

/* .shipify-shipping-comp-data-grid .MuiDataGrid-withBorderColor{
    height: 40px !important;

} */

.MuiTablePagination-selectLabel{
    padding: 4px;
    margin: 0;
}


.shipify-edit-display-name {
    cursor: pointer;
}

.shipify-non-edit-cell {
    color: rgba(0, 0, 0, 0.54);
}

.shipify-status-header .MuiDataGrid-columnHeaderTitleContainer, .shipify-status-cell {
    justify-content: center !important;

}

.shipify-shipping-comp-page-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.shipify-settings-and-plans-page-buttons-section {
    width: 100%;
}

.shipify-settings-and-plans-page-save-button {
    width: fit-content;
    padding: 7px 13px;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    margin-left: auto;
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%), rgba(48, 48, 48, 1);
    box-shadow: 0rem -0.0625rem 0rem 0.0625rem rgba(0, 0, 0, .8) inset, 0rem 0rem 0rem 0.0625rem rgba(48, 48, 48, 1) inset, 0rem 0.03125rem 0rem 0.09375rem rgba(255, 255, 255, .25) inset;
}


.shipify-settings-and-plans-page-save-button:hover {
background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%) , rgba(26, 26, 26, 1);
}


.shipify-settings-and-plans-page-save-button:active {
    background-color: hsl(0, 0%, 30%);
}

/* .shipify-edit-display-name.MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editable.MuiDataGrid-withBorderColor:active, */
.shipify-edit-display-name.MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editable.MuiDataGrid-withBorderColor:focus-within{
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important;
    background-color: #fff;
}


.MuiDataGrid-overlayWrapper .MuiDataGrid-overlayWrapperInner > .MuiDataGrid-overlay > .MuiCircularProgress-root {
    animation: spin 750ms infinite linear !important;
    color: var(--shipify-blue);
    width: 30px !important;
    height: 30px !important;
    border: 2px solid;
    border-top-color: transparent;
    border-radius: 50%;
}

.MuiButtonBase-root.MuiCheckbox-root.Mui-checked,
.MuiSwitch-root.shipify-switch .MuiButtonBase-root.Mui-checked .MuiSwitch-thumb {
    color: var(--shipify-blue);
}

.MuiSwitch-root.shipify-switch .Mui-checked+.MuiSwitch-track {
    background-color: var(--shipify-blue);
}



.shipify-shipping-comp-page-content > .MuiPaper-root {
    border: 1px solid #f5f5f5;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}


.bulk-edit {
    padding: 5px 15px;
    font-size: 11px;
    height: 100%;
    display: flex;
    align-items: center;
}

.bulk-edit .loader-button-text {
    width: max-content;
}


.shipify-bulk-edit-button .bulk-active {
    display: flex;
    min-width: 150px;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
}