.mobile-edit-shipping-group-section,
.edit-shipping-group-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
    padding: var(--topbar-height) 15px 30px 15px;
}

.mobile-edit-shipping-group-section {
    padding-inline: 0;
}

.edit-shipping-group-section-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.mobile-edit-shipping-group-section-subsection-row,
.edit-shipping-group-section-subsection-row {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 50px; 
}

.mobile-edit-shipping-group-section-subsection-row{
    gap: 30px;
}

.mobile-edit-shipping-group-section-subsection-row {
    flex-direction: column;
}

.mobile-edit-shipping-group-section-subsection-header,
.edit-shipping-group-section-subsection-header {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.edit-shipping-group-section-subsection-header-title {
    font-size: 19px;
    font-weight: 500;
}

.edit-shipping-group-section-subsection-header-body {
    font-size: 15px;
    color: grey;
}

.mobile-edit-shipping-group-section-subsection-header-body {
    font-size: 13px;
    color: grey;
}

.mobile-edit-shipping-group-section-subsection-header-body.list,
.edit-shipping-group-section-subsection-header-body.list {
    padding-left: 20px;

    & li > b {
        font-weight: 500;
    }

    & li:not(:last-child) {
        padding-bottom: 10px;
    }
}


.edit-shipping-group-section-subsection-content {
    width: 60%;
}

.mobile-edit-shipping-group-section-subsection-content,
.mobile-edit-shipping-group-section-subsection-header {
    width: 100%;
}

.edit-shipping-group-section-save-button {
    margin-left: auto;
}

.edit-shipping-group-section-shipping-methods-title {
    color: #303030;
    font-size: 16px;
    font-weight: 500;
}

.mobile-edit-shipping-group-section-shipping-methods-title {
    color: #303030;
    font-size: 16px;
    font-weight: 500;
}


.edit-link-section-input-title {
    font-size: 14px;
    color: #303030;
    font-weight: 400;
}


.mobile-multi-select-status-button-title, .multi-select-status-button-title {
    color: rgb(90,90,90) !important;
}

.edit-link-section-link-data-input-title-info-container .edit-link-section-input-title {
    color: rgb(90,90,90);
}


.edit-shipping-group-section-subsection-content .card-container{
    border-radius: 10px;
    border: 1px solid #f5f5f5;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}

.edit-shipping-group-section-subsection-content .account-page-section-content {
    padding: 0;
}


.mobile-edit-shipping-group-section-subsection-content .mobile-account-page-section{
    padding-top: 30px;
}


.mobile-shipping-group-view-container .page-view-topbar-save-button{
    padding: 7px 13px;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%), rgba(48, 48, 48, 1);
    box-shadow: 0rem -0.0625rem 0rem 0.0625rem rgba(0, 0, 0, .8) inset, 0rem 0rem 0rem 0.0625rem rgba(48, 48, 48, 1) inset, 0rem 0.03125rem 0rem 0.09375rem rgba(255, 255, 255, .25) inset;
    margin-right: 15px;
}

.mobile-shipping-group-view-container .page-view-topbar-save-button:hover {
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%) , rgba(26, 26, 26, 1);
}


.MuiDialog-container > .MuiPaper-root.MuiDialog-paper {
    border-radius: 10px;
}


.paydin-dialog .multi-select-dialog-topbar-search-box {
    border-radius: 8px;;
}