.shipify-link {
    display: flex;
    align-items: flex-start;
    gap: 5px;
}

a.shipify-link:visited,
a.shipify-link {
    color: black;
}

.shipify-link-image {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
}