.mobile-shipify-shipping-groups-page,
.shipify-shipping-groups-page {
    display: flex;
    flex-direction: column;
}

.shipify-shipping-groups-page {
    gap: 60px;
    padding: 15px;
}

.mobile-shipify-shipping-groups-page {
    gap: 20px;
    padding: 25px 15px 15px 15px;
}

.shipify-shipping-groups-page-topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.shipify-shipping-groups-page-topbar-title {
    font-size: 30px;
}

.shipify-shipping-groups-page-topbar-create-group-button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 7px 13px;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%), rgba(48, 48, 48, 1);
    box-shadow: 0rem -0.0625rem 0rem 0.0625rem rgba(0, 0, 0, .8) inset, 0rem 0rem 0rem 0.0625rem rgba(48, 48, 48, 1) inset, 0rem 0.03125rem 0rem 0.09375rem rgba(255, 255, 255, .25) inset;
}

.shipify-shipping-groups-page-topbar-create-group-button:hover {
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%) , rgba(26, 26, 26, 1);
}

.shipify-shipping-groups-page-topbar-create-group-button:active {
    background-color: hsl(0, 0%, 30%);
}

.shipify-shipping-groups-page-topbar-create-group-button-image {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.shipify-shipping-groups-page-topbar-create-group-button-text {
    font-size: 15px;
    font-weight: 500;
}


.dialog-button-secondary{
    font-weight: 500;
    border: 1px solid black;
}


.general-dialog-button{
    box-shadow: 0rem -0.0625rem 0rem 0.0625rem rgba(0, 0, 0, .8) inset, 0rem 0rem 0rem 0.0625rem rgba(48, 48, 48, 1) inset, 0rem 0.03125rem 0rem 0.09375rem rgba(255, 255, 255, .25) inset;
}


.dialog-button-primary:hover{
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%) , rgba(26, 26, 26, 1);
}


.dialog-button-secondary:hover{
    background:rgba(0, 0, 0, 15%)
}


.dialog-button-primary{
    background: linear-gradient(180deg, rgba(48, 48, 48, 0) 63.53%, rgba(255, 255, 255, .15) 100%), rgba(48, 48, 48, 1);
}


.shipify-shipping-groups-page > .MuiPaper-root {
    border: 1px solid #f5f5f5;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}