.base-layout-container > .MuiDrawer-root.MuiDrawer-docked > .MuiPaper-root.MuiPaper-elevation {
    min-width: 65px;
}
 

.dashboard-sidebar-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    padding: 15px;
    height: 100%;
}

.dashboard-sidebar-image {
    width: 140px;
    height: 60px;
}

.dashboard-sidebar-image.sidebar-closed {
    width: 35px;
    height: 60px;
}

.dashboard-sidebar-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    width: 100%;
}

.dashboard-sidebar-button {
    /* color: hsl(69, 7%, 40%); */
    color: rgb(90, 90, 90);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    padding: 10px 20px;
    width: 100%;
    border-radius: 5px;
    transition: background-color 200ms ease-out, color 200ms ease-out;
}

.dashboard-sidebar-shipping-groups-button-image {
    fill: black;
}

.dashboard-sidebar-button.sidebar-closed {
    padding: 8px;
}

.dashboard-sidebar-button.selected {
    background: rgba(var(--shipify-blue-rgb), 20%);
    color: var(--shipify-blue);
    font-weight: 500;
}

.dashboard-sidebar-button.disabled {
    color: hsl(69, 7%, 80%);
    cursor: none;
    pointer-events: none;
}

.dashboard-sidebar-button.disabled > .dashboard-sidebar-button-image {
    fill: hsl(69, 7%, 80%);
}

.dashboard-sidebar-button:not(.selected):hover {
    background: rgba(var(--shipify-blue-rgb), 20%);
    color: var(--shipify-blue);

    &.dashboard-sidebar-button:not(.selected) > .dashboard-sidebar-button-image {
        fill: var(--shipify-blue);
    }
}



.dashboard-sidebar-button-label.sidebar-closed {
    opacity: 0;
}

.dashboard-sidebar-button-image {
    width: clamp(12px, 100%, 20px);
    height: 20px;
    flex-shrink: 0;
    fill: rgb(90, 90, 90);
}

.dashboard-sidebar-button.selected > .dashboard-sidebar-button-image:not(.dashboard-sidebar-settings-button-image, .dashboard-sidebar-analytics-button-image),
.dashboard-sidebar-button.selected > .dashboard-sidebar-shorts-button-image > path {
    fill: var(--shipify-blue);
}


.toggle-drawer-button {
    position: fixed;
    top: 50px;
    z-index: 1299;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background: white;
    color: #ccc;
    width: 25px;
    height: 25px;
}

.toggle-drawer-button:hover {
    background: #f6f6f6;
}


.toggle-drawer-button.open {
    left: 228px;
}


.toggle-drawer-button.closed {
    left: 50px;
}

.toggle-drawer-button:focus-visible {
    outline: none;
}

.dashboard-sidebar-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
}


.dashboard-sidebar-button-link {
    font-size: 15px;
    border-radius: 20px;
    color: #fff;
    background: linear-gradient(135deg, #4599e9, #00f1fe);
    width: -webkit-fill-available;
    width: -moz-fit-content;
    max-width: 100%;
    font-weight: 500;
}

.dashboard-sidebar-button-link svg{
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}


.dashboard-sidebar-button-link.sidebar-closed{
    width: 34px;
    height: 34px;

    .dashboard-sidebar-button-label {
        display: none;
    }

    .dashboard-sidebar-book-an-expert-button-image {
        padding-right: 2px;
    }
}


.book-call-modal {
    height: 90vh;
    width: 65vw;
    max-width: 1000px;
    @media (max-width: 992px) {
      width: calc(100vw - 16px) !important;
    }
  }


