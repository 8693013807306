.shipify-splash-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100dvh;
}

.shipify-splash-image {
    width: 300px;
    aspect-ratio: 2;
}

.mobile-shipify-splash-image {
    width: 150px;
    aspect-ratio: 2;
}